@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-column{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

button.sort-arrow-up {
  all: unset;
  width: 10px;
  height: 10px;
  border: 2px solid #32557f;
  border-left: 0;
  border-top: 0;
  margin-top:3px;
  margin-left: 12px;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}

button.sort-arrow-down {
  all: unset;
  width: 10px;
  height: 10px;
  border: 2px solid #32557f;
  border-left: 0;
  border-top: 0;
  margin-bottom:5px;
  margin-left: 12px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

button.highlight-arrow {
  border-color: #970505 !important;
}

.column-name-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  cursor: pointer;
}

.justify-right {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.f-sb{
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.h100{
  height:100%;
}
.center{
  margin:auto;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center ;
          justify-content: center ;
  -webkit-align-self: center;
          align-self: center;
}
.red{
  color:#963131;
}
.blue-link{
  color: #1D65A6;
  cursor: pointer;
}
.bolder{
  font-weight: bolder;
}
.removeTextDecoration{
  text-decoration: none;
}

.mr{
  margin-right: 0.5em;
}
/* .backdrop{
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.4);
  z-index:100;
  cursor: pointer;
} */

/* loader on authentication */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.checkbox-wrapper input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 1.6em;
  height: 1.6em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  border: 0.15em solid #192E5B;
  outline: none;
  cursor: pointer;
}

input.checked {
  background-color: #192E5B;
  position: relative;
}
.App {
  text-align: center;
  background-color: #282c34;
  /* min-height: 100vh; */
  height:100vh;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.headline,h4{
  font-size: calc(10px + 2vmin);
}

.settingCard{
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width:60vw;
  height: 55vh;
  left:20%;
  top:2.5%;
  /* z-index: 50; */
  background-color: white;
  border-radius: 10px;
  /* margin:1.5em; */
  /* font-size: calc(10px + 1vmin); */
}

.templateSettingsCard{
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #eee;
}
.templateSettingsForm{
  width:30vw;
  padding:0 1em;
  /* background-color:white; */
}
.templateSettingsForm > .input-group > input[type=number]{
  width:30%;
  margin:auto;
}

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

nav{
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background-color:#192E5B;
  height:75px;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  box-shadow: #0e1013 0 0 50px;
  padding:0.5em 2em;
  box-sizing: border-box;
}

.logo-text{
  color:white;
  font-family: 'Varela Round', sans-serif;
}
.logo-text-black{
  color:black;
  font-family: 'Varela Round', sans-serif;
}

nav img{
  height:90%;
}
.download-button {
    padding: 5px;
}
.PrintPage{
  height:1100px;
  width:850px;
  /* width: 30vw;
  height: 40vh; */
  background:white;
}
@media print{
  @page{
    size: A4;
  }
  .PrintPage{
    background:transparent;
  }
  .checkTier{
    height:3.5in;
    padding:0.5in;
    /* background-color: darksalmon; */
  }
  .checkStub1{
    height:3.5in;
    /* background:pink; */
  }
  .checkStub2{
    height:4in;
    /* background-color: aquamarine; */
  }
}
.PrintPage > *{
  box-sizing: border-box;
  width:100%;
}
.PrintPage p{
  margin:0;
} 

.checkTier,.checkStub1,.checkStub2{
  font-size: 14px;
}
/* check tier */
.checkTier{
  height:3.5in;
  /* background-color: darksalmon; */
}
.checkLine1,.checkLine2,.checkLine3,.checkLine4{
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width:100%;
  padding:0 5em;
  position:relative;
  box-sizing: border-box;
}
.flex-end{
  -webkit-justify-content:flex-end;
          justify-content:flex-end;
}
/* other check information will be dynamically fetched from context/db? */

/* check stub tier 1 */
.checkStub1{
  height:3.5in;
  /* background:pink; */
  box-sizing: border-box;
  width: 100%;
  padding:1em;
}


/* check stub tier 2 */
.checkStub2{
  height:4in;
  box-sizing: border-box;
  width: 100%;
  padding:1em;
  /* background-color: aquamarine; */
}

.checkStubPreHeader,.checkStubHeader{
  width:100%;
  box-sizing: border-box;
  padding:0.25em;
}
.checkStubPreHeader{
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom:1px solid black;
}
.checkStubHeader{
  border-bottom:3px solid black;
  width:100%;
}
.stubTable{
  width:100%;
  border-collapse: collapse;
}

.editCheckLabel{
  font-size: medium;
  text-align: center;
  padding:0.25em;
}


/* css from PSD */
/* .Group_1 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 2550px;
  height: 3300px;
  z-index: 32;
} */
._Payment_Amount__copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 2126px;
  top: 2393px;
  width: 358px;
  height: 40px;
  z-index: 31;
}
._Bill_Amount__copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 1393px;
  top: 2392px;
  width: 249px;
  height: 40px;
  z-index: 30;
}
._Bill_Number__copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 670px;
  top: 2393px;
  width: 253px;
  height: 40px;
  z-index: 29;
}
._Bill_Date__copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 60px;
  top: 2392px;
  width: 193px;
  height: 40px;
  z-index: 28;
}
.Line_3_copy {
  border-width: 5px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  position: absolute;
  left: 55px;
  top: 2353px;
  width: 2423px;
  height: 0;
  z-index: 27;
}
.Payment_Amount_copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 2171px;
  top: 2284px;
  width: 315px;
  height: 39px;
  z-index: 26;
}
.Bill_Amount_copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 1438px;
  top: 2283px;
  width: 206px;
  height: 31px;
  z-index: 25;
}
.Bill_Number_copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 672px;
  top: 2284px;
  width: 214px;
  height: 31px;
  z-index: 24;
}
.Bill_Date_copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 63px;
  top: 2284px;
  width: 152px;
  height: 31px;
  z-index: 23;
}
._Date__copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 2371px;
  top: 2166px;
  width: 114px;
  height: 40px;
  z-index: 22;
}
.Line_2_copy {
  border-width: 3px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  position: absolute;
  left: 40px;
  top: 2244px;
  width: 2440px;
  height: 0;
  z-index: 21;
}
._Company_Name__copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 61px;
  top: 2166px;
  width: 327px;
  height: 40px;
  z-index: 20;
}
._Payment_Amount_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 2126px;
  top: 1348px;
  width: 358px;
  height: 40px;
  z-index: 19;
}
._Bill_Amount_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 1393px;
  top: 1347px;
  width: 249px;
  height: 40px;
  z-index: 18;
}
._Bill_Number_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 670px;
  top: 1348px;
  width: 253px;
  height: 40px;
  z-index: 17;
}
._Bill_Date_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 60px;
  top: 1347px;
  width: 193px;
  height: 40px;
  z-index: 16;
}
.Line_3 {
  border-width: 5px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  position: absolute;
  left: 55px;
  top: 1308px;
  width: 2423px;
  height: 0;
  z-index: 15;
}
.Payment_Amount {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 2171px;
  top: 1239px;
  width: 315px;
  height: 39px;
  z-index: 14;
}
.Bill_Amount {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 1438px;
  top: 1238px;
  width: 206px;
  height: 31px;
  z-index: 13;
}
.Bill_Number {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 672px;
  top: 1239px;
  width: 214px;
  height: 31px;
  z-index: 12;
}
.Bill_Date {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 63px;
  top: 1239px;
  width: 152px;
  height: 31px;
  z-index: 11;
}
._Date_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 2371px;
  top: 1121px;
  width: 114px;
  height: 40px;
  z-index: 10;
}
.Line_2 {
  border-width: 3px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  position: absolute;
  left: 40px;
  top: 1199px;
  width: 2440px;
  height: 0;
  z-index: 9;
}
._Company_Name_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 61px;
  top: 1121px;
  width: 327px;
  height: 40px;
  z-index: 8;
}
._amount_toWords_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 224px;
  top: 537px;
  width: 332px;
  height: 40px;
  z-index: 7;
}
._Amount_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 1920px;
  top: 411px;
  width: 170px;
  height: 40px;
  z-index: 6;
}
._Date_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 1863px;
  top: 217px;
  width: 115px;
  height: 40px;
  z-index: 5;
}
._Company_Name_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 370px;
  top: 419px;
  width: 328px;
  height: 40px;
  z-index: 4;
}

main{
  width:90vw;
  height: 80vh;
  left:auto;
  position: absolute;
    top: 55%;  
    left: 50%; 

    -webkit-transform: translate(-50%, -50%); 

            transform: translate(-50%, -50%);
  /* z-index: 50; */
}
.report{
  background-color: white;
  /* border-radius: 10px; */
  /* margin:1.5em; */
  max-height:80vh;
  overflow-y: scroll;
  width:100%;
  height:100%;
  font-size: calc(10px + 1vmin);
}
.report-header{
  display:-webkit-flex;
  display:flex;
  background-color: white;
  position: absolute;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  color:black;
  width:100%;
  border-bottom:#0c172d solid 2px;
  padding:0.5em 0;
  z-index: 100;
}
.filter-select{
  background-color: transparent;
  border:0;
  outline:0;
  font-size: 1.5em;
  padding-left:0.5em;
  text-transform: uppercase;
  cursor: pointer;
}
.button-bar{
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}
.button-bar p{
}
.button-bar > p > span{
  font-size: larger;
  color:#1D65A6;
  font-weight: bolder;
}
.button-bar-padding{
  padding:0.25em;
  box-sizing: border-box;
}
.button-bar > *{
  margin: 0 1em;
}
.btn{
  /* border-radius: 5px; */
  /* height:50px; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding:0.5em 1em;
  margin:0.25em 1em;
  color:white;
  border:0;
  text-transform: uppercase;
  font-size: medium;
  cursor: pointer;
}
.add-button{
  background-color: #1D65A6;
}
.button-alt{
  border: 1px solid #1D65A6;
  color:#1D65A6;
  background-color: transparent;
}
.remove-button{
  background-color: #963131;
}
.pay-button{
  background-color: #54A245;
}
.listTable{
  position: relative;
  top:10%;
  width:100%;
  border-collapse: collapse; 
}
.listTable tbody{
  overflow-y: scroll;
  max-height: inherit;
}
.listTable tr:nth-child(even) {
  background-color: #dfe6f6;
}
/* { */
  /* border-bottom:#04070e solid 1px; */
/* } */
.listTable td{
  padding:0.5em;
}
.listTable th{
  padding:0.5em;
}
td input[type=number]{
  width:25%;
}
.modalHead{
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #EEE;
  padding:0.5em;
}
.modalBody{
  padding:0.25em;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.modalBody form{
  width:90%;
  margin:auto;
}
.closeButton{
  background:transparent;
  border:0;
  outline:0;
  cursor: pointer;
}
.modalButton{
  margin:0.5em auto;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center ;
          justify-content: center ;
  -webkit-align-self: center;
          align-self: center;
}
.input-group{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width:100%;
  margin: 0.5em 0;
}
.input-group input, .input-group select{
  padding:0.5em;
}
.printerIcon{
  display:block;
  margin: 1em auto;
  width:50%;
  /* width:auto; */
}
.updateCheckHeader{
  display: -webkit-flex;
  display: flex;
  width:100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  font-size: 11px;
}
.updateBillRow{
  display:-webkit-flex;
  display:flex;
  border-bottom: 1px solid rgba(126,124,124,0.5);
  padding: 0.75em 0;
}
.updateBillRow input{
  width:30%;
}
.updateBillLabel{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width:70%;
}
.updateBillSpan{
  display: block;
  color:gray;
}
.footnote{
  /* position: sticky; */
  /* bottom: 0; */
  padding:1em;
  border-bottom: 1px solid rgba(126,124,124,0.5);
  font-size:smaller;
}

.bank-modalBackground{
 width: 99vw;
 height:90vh;
 background-color: rgba(200,200,200,0.5);
 position:absolute;
 top:-100%;
 left:-5%;
 display: -webkit-flex;
 display: flex;
 -webkit-justify-content: center;
         justify-content: center;
 -webkit-align-items: center;
         align-items: center; 
 z-index: 100;
 overflow: hidden !important;
}

.bank-modalContainer{
  width:25vw;
  height:30vw;
  background-color: white;
  box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 25px;
}
.bankModalTitle{
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.titleCloseBtn button{
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.bankModalBody{
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 50% 1;
          flex: 50% 1;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}

.bankModalFooter{
  -webkit-flex: 20% 1;
          flex: 20% 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}

.search {
    border: 1px solid rgb(219, 219, 219);
    border-radius: 5px;
    height: 20px;
    width: 80%;
    padding: 2px 10px 2px 10px;
    outline: 0;
    background-color: #f5f5f5;
}
.absoluteCenter{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.loginCard{
  width:30vw;
  height:40vh;
  background-color: white;
  padding:5%;
  box-sizing: border-box;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.loginLogoImg{
  max-height:2em;
}
