.bank-modalBackground{
 width: 99vw;
 height:90vh;
 background-color: rgba(200,200,200,0.5);
 position:absolute;
 top:-100%;
 left:-5%;
 display: flex;
 justify-content: center;
 align-items: center; 
 z-index: 100;
 overflow: hidden !important;
}

.bank-modalContainer{
  width:25vw;
  height:30vw;
  background-color: white;
  box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}
.bankModalTitle{
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn{
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button{
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.bankModalBody{
  display: flex;
  flex: 50%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bankModalFooter{
  flex: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}