main{
  width:90vw;
  height: 80vh;
  left:auto;
  position: absolute;
    top: 55%;  
    left: 50%; 

    transform: translate(-50%, -50%);
  /* z-index: 50; */
}
.report{
  background-color: white;
  /* border-radius: 10px; */
  /* margin:1.5em; */
  max-height:80vh;
  overflow-y: scroll;
  width:100%;
  height:100%;
  font-size: calc(10px + 1vmin);
}
.report-header{
  display:flex;
  background-color: white;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  color:black;
  width:100%;
  border-bottom:#0c172d solid 2px;
  padding:0.5em 0;
  z-index: 100;
}
.filter-select{
  background-color: transparent;
  border:0;
  outline:0;
  font-size: 1.5em;
  padding-left:0.5em;
  text-transform: uppercase;
  cursor: pointer;
}
.button-bar{
  display:flex;
  justify-content: space-around;
  align-items: center;
}
.button-bar p{
}
.button-bar > p > span{
  font-size: larger;
  color:#1D65A6;
  font-weight: bolder;
}
.button-bar-padding{
  padding:0.25em;
  box-sizing: border-box;
}
.button-bar > *{
  margin: 0 1em;
}
.btn{
  /* border-radius: 5px; */
  /* height:50px; */
  width: fit-content;
  padding:0.5em 1em;
  margin:0.25em 1em;
  color:white;
  border:0;
  text-transform: uppercase;
  font-size: medium;
  cursor: pointer;
}
.add-button{
  background-color: #1D65A6;
}
.button-alt{
  border: 1px solid #1D65A6;
  color:#1D65A6;
  background-color: transparent;
}
.remove-button{
  background-color: #963131;
}
.pay-button{
  background-color: #54A245;
}
.listTable{
  position: relative;
  top:10%;
  width:100%;
  border-collapse: collapse; 
}
.listTable tbody{
  overflow-y: scroll;
  max-height: inherit;
}
.listTable tr:nth-child(even) {
  background-color: #dfe6f6;
}
/* { */
  /* border-bottom:#04070e solid 1px; */
/* } */
.listTable td{
  padding:0.5em;
}
.listTable th{
  padding:0.5em;
}
td input[type=number]{
  width:25%;
}
.modalHead{
  display:flex;
  justify-content: space-between;
  background-color: #EEE;
  padding:0.5em;
}
.modalBody{
  padding:0.25em;
  display:flex;
  flex-direction: column;
  align-items: center;
}
.modalBody form{
  width:90%;
  margin:auto;
}
.closeButton{
  background:transparent;
  border:0;
  outline:0;
  cursor: pointer;
}
.modalButton{
  margin:0.5em auto;
  text-align: center;
  display: flex;
  justify-content: center ;
  align-self: center;
}
.input-group{
  display: flex;
  flex-direction: column;
  width:100%;
  margin: 0.5em 0;
}
.input-group input, .input-group select{
  padding:0.5em;
}
.printerIcon{
  display:block;
  margin: 1em auto;
  width:50%;
  /* width:auto; */
}
.updateCheckHeader{
  display: flex;
  width:100%;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
}
.updateBillRow{
  display:flex;
  border-bottom: 1px solid rgba(126,124,124,0.5);
  padding: 0.75em 0;
}
.updateBillRow input{
  width:30%;
}
.updateBillLabel{
  display: flex;
  flex-direction: column;
  width:70%;
}
.updateBillSpan{
  display: block;
  color:gray;
}
.footnote{
  /* position: sticky; */
  /* bottom: 0; */
  padding:1em;
  border-bottom: 1px solid rgba(126,124,124,0.5);
  font-size:smaller;
}
