.App {
  text-align: center;
  background-color: #282c34;
  /* min-height: 100vh; */
  height:100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.headline,h4{
  font-size: calc(10px + 2vmin);
}

.settingCard{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width:60vw;
  height: 55vh;
  left:20%;
  top:2.5%;
  /* z-index: 50; */
  background-color: white;
  border-radius: 10px;
  /* margin:1.5em; */
  /* font-size: calc(10px + 1vmin); */
}

.templateSettingsCard{
  display:flex;
  justify-content: space-between;
  background-color: #eee;
}
.templateSettingsForm{
  width:30vw;
  padding:0 1em;
  /* background-color:white; */
}
.templateSettingsForm > .input-group > input[type=number]{
  width:30%;
  margin:auto;
}

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
