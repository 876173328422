body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

button.sort-arrow-up {
  all: unset;
  width: 10px;
  height: 10px;
  border: 2px solid #32557f;
  border-left: 0;
  border-top: 0;
  margin-top:3px;
  margin-left: 12px;
  transform: rotate(225deg);
}

button.sort-arrow-down {
  all: unset;
  width: 10px;
  height: 10px;
  border: 2px solid #32557f;
  border-left: 0;
  border-top: 0;
  margin-bottom:5px;
  margin-left: 12px;
  transform: rotate(45deg);
}

button.highlight-arrow {
  border-color: #970505 !important;
}

.column-name-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.justify-right {
  justify-content: flex-end !important;
}

.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.f-sb{
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.h100{
  height:100%;
}
.center{
  margin:auto;
  text-align: center;
  display: flex;
  justify-content: center ;
  align-self: center;
}
.red{
  color:#963131;
}
.blue-link{
  color: #1D65A6;
  cursor: pointer;
}
.bolder{
  font-weight: bolder;
}
.removeTextDecoration{
  text-decoration: none;
}

.mr{
  margin-right: 0.5em;
}
/* .backdrop{
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.4);
  z-index:100;
  cursor: pointer;
} */

/* loader on authentication */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.checkbox-wrapper input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 1.6em;
  height: 1.6em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  border: 0.15em solid #192E5B;
  outline: none;
  cursor: pointer;
}

input.checked {
  background-color: #192E5B;
  position: relative;
}