.PrintPage{
  height:1100px;
  width:850px;
  /* width: 30vw;
  height: 40vh; */
  background:white;
}
@media print{
  @page{
    size: A4;
  }
  .PrintPage{
    background:transparent;
  }
  .checkTier{
    height:3.5in;
    padding:0.5in;
    /* background-color: darksalmon; */
  }
  .checkStub1{
    height:3.5in;
    /* background:pink; */
  }
  .checkStub2{
    height:4in;
    /* background-color: aquamarine; */
  }
}
.PrintPage > *{
  box-sizing: border-box;
  width:100%;
}
.PrintPage p{
  margin:0;
} 

.checkTier,.checkStub1,.checkStub2{
  font-size: 14px;
}
/* check tier */
.checkTier{
  height:3.5in;
  /* background-color: darksalmon; */
}
.checkLine1,.checkLine2,.checkLine3,.checkLine4{
  display:flex;
  justify-content: space-between;
  width:100%;
  padding:0 5em;
  position:relative;
  box-sizing: border-box;
}
.flex-end{
  justify-content:flex-end;
}
/* other check information will be dynamically fetched from context/db? */

/* check stub tier 1 */
.checkStub1{
  height:3.5in;
  /* background:pink; */
  box-sizing: border-box;
  width: 100%;
  padding:1em;
}


/* check stub tier 2 */
.checkStub2{
  height:4in;
  box-sizing: border-box;
  width: 100%;
  padding:1em;
  /* background-color: aquamarine; */
}

.checkStubPreHeader,.checkStubHeader{
  width:100%;
  box-sizing: border-box;
  padding:0.25em;
}
.checkStubPreHeader{
  display:flex;
  justify-content: space-between;
  border-bottom:1px solid black;
}
.checkStubHeader{
  border-bottom:3px solid black;
  width:100%;
}
.stubTable{
  width:100%;
  border-collapse: collapse;
}

.editCheckLabel{
  font-size: medium;
  text-align: center;
  padding:0.25em;
}


/* css from PSD */
/* .Group_1 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 2550px;
  height: 3300px;
  z-index: 32;
} */
._Payment_Amount__copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 2126px;
  top: 2393px;
  width: 358px;
  height: 40px;
  z-index: 31;
}
._Bill_Amount__copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 1393px;
  top: 2392px;
  width: 249px;
  height: 40px;
  z-index: 30;
}
._Bill_Number__copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 670px;
  top: 2393px;
  width: 253px;
  height: 40px;
  z-index: 29;
}
._Bill_Date__copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 60px;
  top: 2392px;
  width: 193px;
  height: 40px;
  z-index: 28;
}
.Line_3_copy {
  border-width: 5px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  position: absolute;
  left: 55px;
  top: 2353px;
  width: 2423px;
  height: 0;
  z-index: 27;
}
.Payment_Amount_copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 2171px;
  top: 2284px;
  width: 315px;
  height: 39px;
  z-index: 26;
}
.Bill_Amount_copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 1438px;
  top: 2283px;
  width: 206px;
  height: 31px;
  z-index: 25;
}
.Bill_Number_copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 672px;
  top: 2284px;
  width: 214px;
  height: 31px;
  z-index: 24;
}
.Bill_Date_copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 63px;
  top: 2284px;
  width: 152px;
  height: 31px;
  z-index: 23;
}
._Date__copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 2371px;
  top: 2166px;
  width: 114px;
  height: 40px;
  z-index: 22;
}
.Line_2_copy {
  border-width: 3px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  position: absolute;
  left: 40px;
  top: 2244px;
  width: 2440px;
  height: 0;
  z-index: 21;
}
._Company_Name__copy {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 61px;
  top: 2166px;
  width: 327px;
  height: 40px;
  z-index: 20;
}
._Payment_Amount_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 2126px;
  top: 1348px;
  width: 358px;
  height: 40px;
  z-index: 19;
}
._Bill_Amount_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 1393px;
  top: 1347px;
  width: 249px;
  height: 40px;
  z-index: 18;
}
._Bill_Number_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 670px;
  top: 1348px;
  width: 253px;
  height: 40px;
  z-index: 17;
}
._Bill_Date_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 60px;
  top: 1347px;
  width: 193px;
  height: 40px;
  z-index: 16;
}
.Line_3 {
  border-width: 5px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  position: absolute;
  left: 55px;
  top: 1308px;
  width: 2423px;
  height: 0;
  z-index: 15;
}
.Payment_Amount {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 2171px;
  top: 1239px;
  width: 315px;
  height: 39px;
  z-index: 14;
}
.Bill_Amount {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 1438px;
  top: 1238px;
  width: 206px;
  height: 31px;
  z-index: 13;
}
.Bill_Number {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 672px;
  top: 1239px;
  width: 214px;
  height: 31px;
  z-index: 12;
}
.Bill_Date {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 63px;
  top: 1239px;
  width: 152px;
  height: 31px;
  z-index: 11;
}
._Date_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 2371px;
  top: 1121px;
  width: 114px;
  height: 40px;
  z-index: 10;
}
.Line_2 {
  border-width: 3px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  position: absolute;
  left: 40px;
  top: 1199px;
  width: 2440px;
  height: 0;
  z-index: 9;
}
._Company_Name_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 61px;
  top: 1121px;
  width: 327px;
  height: 40px;
  z-index: 8;
}
._amount_toWords_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 224px;
  top: 537px;
  width: 332px;
  height: 40px;
  z-index: 7;
}
._Amount_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 1920px;
  top: 411px;
  width: 170px;
  height: 40px;
  z-index: 6;
}
._Date_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 1863px;
  top: 217px;
  width: 115px;
  height: 40px;
  z-index: 5;
}
._Company_Name_ {
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 370px;
  top: 419px;
  width: 328px;
  height: 40px;
  z-index: 4;
}
