.absoluteCenter{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loginCard{
  width:30vw;
  height:40vh;
  background-color: white;
  padding:5%;
  box-sizing: border-box;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.loginLogoImg{
  max-height:2em;
}