@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

nav{
  display:flex;
  justify-content: space-between;
  align-items: center;
  background-color:#192E5B;
  height:75px;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  box-shadow: #0e1013 0 0 50px;
  padding:0.5em 2em;
  box-sizing: border-box;
}

.logo-text{
  color:white;
  font-family: 'Varela Round', sans-serif;
}
.logo-text-black{
  color:black;
  font-family: 'Varela Round', sans-serif;
}

nav img{
  height:90%;
}